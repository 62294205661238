/*
  对axios进行封装。
  建议初始化一个实例并作为全局唯一使用。
  let http = new Http()
  http.request({
    url: '/user',
    method: 'get',
    success: (resp) => {
    },
    error: (resp) => {
    }
  })
  你可以使用便捷的请求函数了代替直接使用request函数，如：
  http.get(path, {})
  http.post(path, {})

  拦截器：
  =======================================
  可以使用属性interceptors来配置拦截器。

  # Request拦截器，发送请求之前被执行。
  httpInstance.interceptors.request = function (config) {
    // 在这里你有机会修改config配置，这个config就是你执行http.request传过来的config。
  }

  # Response拦截器，请求结束后执行，这包括请求被取消，服务器没有响应，也仍然会执行。
  httpInstance.interceptors.response = function (success, resp) {
    // 这是拦截器默认返回的内容
    return {isSuccess: success, data: resp}
  }
  success：仅仅表示axios的then和catch，如果请求成功（在then中执行拦截器），那么success为true，否则为false。
  resp：取决与success的值，如果success为true，那么resp是axios在then回调里的参数，如果为false，结构如下：
    {
      error: Object, // axios在catch回调里的原始参数
      reqStatus: String, // 'resp'表示服务器有响应，'noResp'表示服务器没有响应，'unknow'表示未知情况
      status: int, // http状态码
      headers: Object, // http响应头
      resp: Object, // 服务器的响应数据
      isUserCancel: boolean // true表示用户取消了请求，比如使用http.load()函数
    }
  注意：只有reqStatus == 'resp'时，status，headers，resp才有数据。
  Response拦截器还需要返回一个结构：
    isSuccess：逻辑成功与否，为true会执行success回调，false会执行error回调。
    data：传给complete, success, error, final等回调的数据。
    ignoreCallback：一个普通对象，可以告诉http忽略后面哪些回调函数，如{ success: true, error: true }

  请求配置：
  =======================================
  httpInstance.request(config)
  config配置基本和axios的配置一致，但额外增加了如下配置项：
    context：回调函数的上下文。
    disableBaseUrl：默认你的url会拼接baseUrl前缀，如果不想拼接，设置true。
    success (resp, config)：请求逻辑成功，与http请求是否成功不同，这是取决于response拦截器，resp参数是response拦截器的data数据。
    error (resp, config)：请求逻辑失败，resp参数是response拦截器的data数据。
    complete (success, data, config)：请求完成后，参数是response拦截器返回的isSuccess和data。
    final (success, data, config)：最后一个回调，参数与complete一致。
    cancel (config)：用户取消请求的回调。
    ignoreCallbackOnUserCancel：默认false，为true时，当请求是由用户取消的，不会执行任何回调函数。

  这些回调的顺序为：complete > [success | error | cancel] > final
  success，error和cancel只会执行其中一个。

  全局请求配置：
  =======================================
  可以使用属性callbacks来为上面的5个回调配置一个默认的行为，如果用户提供了相应的回调，会覆盖全局的配置。
  httpInstance.callbacks.error = function (resp) {
    alert('show error message')
  }
 */
import axios from 'axios'

export default class Http {
  baseUrl = ''

  interceptors = {
    request: function (config) {
    },
    response: function (success, resp) {
      return {
        isSuccess: success,
        data: resp
      }
    }
  }

  callbacks = {
    complete: function () { },
    success: function () { },
    error: function () { },
    cancel: function () { },
    final: function () { }
  }

  configs = {
    uploadTimeout: 1000 * 60 * 60
  }

  constructor () {
    this.instance = axios.create({})
    this.requesting = {}
  }

  get (path, config) {
    config = config || {}
    config.url = path
    config.method = 'get'
    return this.request(config)
  }

  /**
   * 等同{@link get}函数，但是会终止上一次相同地址的请求，比如：
   * 第一次请求：/users
   * 第二次请求：/users 如果第一次仍然未完成，则会被终止
   * 注意：此函数的上一个请求被取消时，是不会收到回调函数的通知的。
   * @param path
   * @param config
   */
  load (path, config) {
    config.ignoreCallbackOnUserCancel = true
    this.requesting[path] && this.requesting[path].cancel('上一个请求已被终止：' + path)
    this.requesting[path] = this.get(path, config)
    return this.requesting[path]
  }

  post (path, config) {
    config = config || {}
    config.url = path
    config.method = 'post'
    return this.request(config)
  }

  upload (path, config) {
    if (config.timeout === undefined) {
      config.timeout = this.configs.uploadTimeout
    }
    return this.post(path, config)
  }

  put (path, config) {
    config = config || {}
    config.url = path
    config.method = 'put'
    return this.request(config)
  }

  del (path, config) {
    config = config || {}
    config.url = path
    config.method = 'delete'
    return this.request(config)
  }

  patch (path, config) {
    config = config || {}
    config.url = path
    config.method = 'patch'
    return this.request(config)
  }

  request (config) {
    config = merge({
      url: null,
      method: null,
      context: null,
      complete: null,
      success: null,
      error: null,
      cancel: null,
      final: null,
      ignoreCallbackOnUserCancel: false
    }, this.callbacks, config)
    const path = config.url
    const requesting = this.requesting
    const source = axios.CancelToken.source()
    config.cancelToken = source.token
    if (config.disableBaseUrl !== true) {
      config.url = this.baseUrl + config.url
    }

    this.interceptors.request(config)

    this.instance
      .request(config)
      .then(resp => {
        delete requesting[path]
        const responseData = this.interceptors.response(true, resp)
        if (responseData == null) {
          throw new Error('Http get return empty value from response interceptor.')
        }
        const ic = responseData.ignoreCallback
        execCallback(ic, 'complete', [responseData.isSuccess, responseData.data], config)
        if (responseData.isSuccess) {
          execCallback(ic, 'success', [responseData.data], config)
        } else {
          execCallback(ic, 'error', [responseData.data], config)
        }
        execCallback(ic, 'final', [responseData.isSuccess, responseData.data], config)
      })
      .catch(error => {
        const info = {
          error: error
        }
        let reqStatus
        if (error.response) {
          reqStatus = 'resp'
        } else if (error.request) {
          reqStatus = 'noResp'
        } else {
          reqStatus = 'unknow'
        }
        info.reqStatus = reqStatus
        if (info.reqStatus === 'resp') {
          info.status = error.response.status
          info.resp = error.response.data
          info.headers = error.response.headers
        }
        info.isUserCancel = axios.isCancel(error)

        if (!info.isUserCancel) {
          delete requesting[path]
        }
        if (info.isUserCancel && config.ignoreCallbackOnUserCancel) {
          return
        }

        const responseData = this.interceptors.response(false, info)
        if (responseData == null) {
          throw new Error('Http get return empty value from response interceptor.')
        }
        const ic = responseData.ignoreCallback
        execCallback(ic, 'complete', [responseData.isSuccess, responseData.data], config)
        if (info.isUserCancel) {
          execCallback(ic, 'cancel', [], config)
        } else {
          if (responseData.isSuccess) {
            execCallback(ic, 'success', [responseData.data], config)
          } else {
            execCallback(ic, 'error', [responseData.data], config)
          }
        }
        execCallback(ic, 'final', [responseData.isSuccess, responseData.data], config)
      })
    return {
      cancel: function (message) {
        source.cancel(message)
      }
    }
  }
}

/**
 * 执行http回调的便捷工具函数。
 * @param ignoreCallback 如果ignoreCallback中存在callbackName，那么函数不会执行任何操作直接返回。
 * @param callbackName 需要被执行的回调名称。
 * @param callbackArgs 不可为null，没有的话传[]。
 * @param config http请求，会作为http回调的最后一个参数。
 */
function execCallback (ignoreCallback, callbackName, callbackArgs, config) {
  if (ignoreCallback && ignoreCallback[callbackName] === true) {
  } else {
    call(config.context, config[callbackName], [...callbackArgs, config])
  }
}

function call (context, func, params, catchError) {
  if (Array.isArray(context)) {
    params = context
    context = null
  }
  if (catchError === true || catchError === undefined) {
    try {
      return func.apply(context, params)
    } catch (e) {
      console && console.error(e)
    }
  } else {
    return func.apply(context, params)
  }
}

function merge () {
  const first = arguments[0]
  for (let i = 1; i < arguments.length; i++) {
    const arg = arguments[i]
    for (const key of Object.keys(arg)) {
      if (Object.prototype.hasOwnProperty.call(arg, key) && arg[key] !== undefined && arg[key] != null) {
        first[key] = arg[key]
      }
    }
  }
  return first
}
