<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="广告地址编辑"
    @save="onSave"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="地址url" class="app_required-input">
        <a-input v-model="form.url"/>
      </a-form-model-item>
      <a-form-model-item label="排序" class="app_required-input">
        <a-input-number v-model="form.seq"/>
      </a-form-model-item>
      <a-form-model-item label="多少秒后可关闭" class="app_required-input">
        <a-input-number v-model="form.seeDuration" :min="5" />
      </a-form-model-item>
    </a-form-model>
  </drawer>
</template>

<script>
import { add, update } from '@/http/api/ads'
import AdsModel from '@/data/model/ads'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: new AdsModel()
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.form.id
    },
    isHandleEditable () {
      return !this.isUpdate
    }
  },
  methods: {
    open (proposalId, model) {
      this.visible = true
      if (model) {
        this.form = new AdsModel(model)
      } else {}
      this.form.proposalId = proposalId
    },
    onClose () {
      this.form = new AdsModel()
    },
    onSave () {
      this.save()
    },
    save () {
      this.closable = false
      this.saveButtonLoading = true
      const api = this.isUpdate ? update() : add()
      api
        .complete((success) => {
          this.closable = true
          this.saveButtonLoading = false
          if (success) {
            this.$nextTick(() => (this.visible = false))
          }
        })
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved', new AdsModel(resp.data))
        })
        .send(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
