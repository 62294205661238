<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="$refs.editor.open()">添加</a-button>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="$refs.editor.open(row)">编辑</a-button>
        <a-button size="small" type="primary" @click="handleUpdateCurrent(row)" v-show="!row.current">使用预设</a-button>
        <a-button size="small" type="primary" @click="$router.push({name: adsRouteName, params: {id: row.id}})" >广告</a-button>
        <a-button size="small" type="primary" @click="$router.push({name: awardsItemsRouteName, params: {id: row.id}})" >奖项</a-button>
      </div>
      <status slot="current" slot-scope="current" :status="current" enabled-text="使用中" disabled-text="N"></status>
    </a-table>

    <proposal-editor ref="editor" @saved="handleProposalSave"></proposal-editor>
  </app-page>
</template>

<script>
import { ROUTE_PROPOSAL, ROUTE_ADS, ROUTE_AWARDS_ITEMS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listByHandle, updateCurrent } from '@/http/api/proposal'
import ProposalEditor from './comp/proposal-editor'
import kit from '@/utils/kit'

export default {
  components: { ProposalEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_PROPOSAL,
      pageLoading: false,
      dataColumns: [
        { title: '操作', width: 150, scopedSlots: { customRender: 'action' } },
        { title: '方案名称', width: 150, dataIndex: 'name' },
        { title: 'Current', width: 100, dataIndex: 'current', scopedSlots: { customRender: 'current' } },
        { title: '开始时间', width: 150, dataIndex: 'startDate' },
        { title: '结束时间', width: 150, dataIndex: 'endDate' },
        { title: '创建时间', width: 150, dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false,
      adsRouteName: ROUTE_ADS,
      awardsItemsRouteName: ROUTE_AWARDS_ITEMS
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listByHandle()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send('')
    },
    handleProposalSave (p) {
      kit.arr.pushOrReplace(this.dataList, p)
    },
    handleUpdateCurrent (row) {
      this.$confirm({
        title: '确认',
        content: `确定要使用【${row.name}】这个预设参数作为抽奖参数吗？`,
        onOk: () => {
          updateCurrent()
            .complete(() => (this.pageLoading = false))
            .success(() => {
              this.$message.success('预设参数已经更换。')
              this.loadData()
            })
            .send(row.id)
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
