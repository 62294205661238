/**
 * @author jiangxingshang
 * @date 2022/12/7
 */
import kit from '@/utils/kit'

export default class ProtectResource {
  constructor (initData) {
    this.id = null
    this.name = null
    this.description = null
    this.enabled = true
    this.groupName = null

    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
