/**
 * @author zf
 * @date 2024/06/07
 */

import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


export const list = defineApi((config, awardsQuery) => {
  config.params = awardsQuery
  http.get(path('/awards/list'), config)
})

export const getById = defineApi((config, id) => {
  config.params = { id: id }
  http.get(path('/awards/get/by_id'), config)
})

export const add = defineApi((config, awardsList) => {
  config.data = awardsList
  http.post(path('/awards/bind'), config)
})

export const listFromShopify = defineApi((config, queryInfo) => {
  config.params = queryInfo
  http.get(path('/awards/list/from_shopify'), config)
})

export const delAwards = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/awards/delete'), config)
})

