/**
 * @author zf
 * @date 2024/06/14
 */
import PageSize from './pagesize'

export default class CustomerPrizesQuery extends PageSize {
  constructor () {
    super()
    this.createUserId = null
    this.code = null
  }
}
