<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="$refs.editor.open($route.params.id)">添加</a-button>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="$refs.editor.open($route.params.id,row)">编辑</a-button>
      </div>
    </a-table>

    <ads-editor ref="editor" @saved="handleAdsSave"></ads-editor>
  </app-page>
</template>

<script>
import { ROUTE_ADS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listByProposalId } from '@/http/api/ads'
import AdsEditor from './comp/ads-editor'
import kit from '@/utils/kit'

export default {
  components: { AdsEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_ADS,
      pageLoading: false,
      dataColumns: [
        { title: '操作', width: 80, scopedSlots: { customRender: 'action' } },
        { title: '广告地址', dataIndex: 'url' },
        { title: '排序', width: 60, dataIndex: 'seq' },
        { title: '多少秒后可关闭', width: 120, dataIndex: 'seeDuration' },
        { title: '创建时间', width: 120, dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listByProposalId()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.$route.params.id)
    },
    handleAdsSave (p) {
      kit.arr.pushOrReplace(this.dataList, p)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
