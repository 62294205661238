<!--
  分页组件，封装a-pagination组件，使应用系统的分页组件能统一外观和使用方式。

  外部属性：
    total 请求数据后需要更新该属性，使分页组件可以正常工作。
    start.sync 请求数据的开始位置，从0开始，这不是页码，而是数据行数的位置。
    limit.sync 每页的行数。

  在父组件中通过设置start和limit的属性为sync，然后watch这两个属性，属性发生改变时应该重新加载数据。
-->
<template>
  <div class="pagination">
    <a-pagination
        v-model="currentPage"
        :size="size"
        :page-size-options="pageSizes"
        :total="total"
        :show-size-changer="showSize"
        :page-size="limit"
        :show-total="(total, range) => `当前：${range[0]}-${range[1]} 总数：${total}`"
        @showSizeChange="onSizeChanged"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    size: { type: String, default: '' },
    total: { type: Number, required: true },
    limit: { type: Number, required: true },
    start: { type: Number, required: true },
    showSize: { type: Boolean, default: true },
    pageSizes: {
      type: Array,
      default () {
        return ['20', '40', '60', '100', '200']
      }
    }
  },
  computed: {
    currentPage: {
      get () {
        if (this.start === 0) {
          return 1
        } else if (this.start !== undefined) {
          return parseInt(this.start / this.limit) + 1
        } else {
          return 1
        }
      },
      set (page) {
        const start = (page - 1) * this.limit
        this.$emit('update:start', start)
      }
    }
  },
  methods: {
    onSizeChanged (index, limit) {
      this.$emit('update:limit', limit)
    }
  }
}
</script>

<style lang="less" scoped>
  @deep: ~'>>>';
  @color: #f9f9f9;
  .pagination {
    padding: 8px 10px;
    text-align: center;
  }
  .pagination @{deep} .ant-pagination {
    text-align: left;
    display: inline-block;
  }
</style>
