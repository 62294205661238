<template>
  <transition name="zoom" :duration="50">
    <ul class="context-menu"
         v-transfer-dom
         :data-transfer="true"
         v-show="visible"
         :style="style"
    >
      <slot></slot>
    </ul>
  </transition>
</template>

<script>
import TransferDom from '@/directives/transfer-dom'
export default {
  name: 'context-menu',
  data () {
    return {
      visible: false,
      x: 0,
      y: 0,
      menuData: null
    }
  },
  directives: { TransferDom },
  watch: {
    visible (v) {
      if (v) {
        document.body.addEventListener('click', this.handleBodyClick)
      } else {
        document.body.removeEventListener('click', this.handleBodyClick)
        this.menuData = null
      }
    }
  },
  computed: {
    style () {
      return {
        left: `${this.x}px`,
        top: `${this.y}px`
      }
    }
  },
  methods: {
    /**
     * @param position
     * @param data 带到menuItemClick事件中的数据
     */
    open (position, data) {
      this.x = position.x
      this.y = position.y
      this.visible = true
      this.menuData = data
    },
    close () {
      this.visible = false
    },
    handleBodyClick (event) {
      const notOutside = this.$el.contains(event.target) || (
        this.isClick && this.references.some(ref => ref.el.contains(event.target))
      )
      if (!notOutside) {
        this.visible = false
      }
    }
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.handleBodyClick)
  }
}
</script>

<style lang="less" scoped>
.context-menu {
  padding: 5px 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  min-width: 100px;
  max-width: 200px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.3);
  border-radius: 3px;
  overflow: hidden;
  z-index: 3000;
}
</style>
