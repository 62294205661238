/*
  如果你的页面组件需要用到分页组件时，可以使用本配置混合到组件中，你的组件必须提供一个loadData函数来加载数据。

  用例：

  import PaginationMixins from ...
  // 模板
  <template>
    <div>
      <!--混合配置中提供了一个reload函数来重新加载你的数据-->
      <el-button @click="reloadData"></el-button>
      <!--你的表格组件-->
      <el-table>...</el-table>
      <!--全局的分页组件-->
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
  </template>
  // 代码
  export default {
    mixins: [PaginationMixins],
    methods: {
      loadData () {
        // 加载数据
        // 请求时请的将this.page.start和this.page.limit参数发给服务端
        // 然后在请求成功时，将服务端返回的记录总数赋予给this.page.total属性
      }
    }
  }
 */
import kit from '@/utils/kit'
export default {
  data () {
    return {
      page: {
        total: 0,
        start: 0,
        limit: 20
      }
    }
  },
  watch: {
    'page.start' () {
      this.loadData()
    },
    'page.limit' () {
      this.reloadData()
    }
  },
  methods: {
    reloadData () {
      if (this.page.start === 0) {
        this.loadData()
      } else {
        this.page.start = 0
      }
    },
    buildRequestParams (params) {
      const p = kit.obj.deepCopy(params)
      p.start = this.page.start
      p.limit = this.page.limit
      return p
    }
  }
}
