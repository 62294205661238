/**
 * @author zf
 * @date 2024/06/07
 */
export default class ShopifyPage {
  constructor () {
    this.hasNextPage = false
    this.hasPreviousPage = false
    this.startCursor = null
    this.endCursor = null
  }
}
