/**
 * @author zf
 * @date 2024/06/04
 */

import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


export const listByHandle = defineApi((config, handle) => {
  config.params = { handle: handle }
  http.get(path('/proposal/list/by_handle'), config)
})

export const getById = defineApi((config, id) => {
  config.params = { id: id }
  http.get(path('/proposal/get/by_id'), config)
})

export const add = defineApi((config, proposal) => {
  config.data = proposal
  http.post(path('/proposal/add'), config)
})

export const update = defineApi((config, proposal) => {
  config.data = proposal
  http.post(path('/proposal/update'), config)
})

export const updateCurrent = defineApi((config, id) => {
  config.params = { id: id }
  http.post(path('/proposal/update/current'), config)
})
