<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="方案编辑"
    @save="onSave"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="方案名称" class="app_required-input">
        <a-input v-model="form.name"/>
      </a-form-model-item>
      <a-form-model-item label="开始时间" class="app_required-input">
        <a-date-picker v-model="form.startDate"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>
      <a-form-model-item label="结束时间" class="app_required-input">
        <a-date-picker v-model="form.endDate"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>

    </a-form-model>
  </drawer>
</template>

<script>
import { add, update } from '@/http/api/proposal'
import ProposalModel from '@/data/model/proposal'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: new ProposalModel()
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.form.id
    },
    isHandleEditable () {
      return !this.isUpdate
    }
  },
  methods: {
    open (model) {
      this.visible = true
      if (model) {
        this.form = new ProposalModel(model)
      }
    },
    onClose () {
      this.form = new ProposalModel()
    },
    onSave () {
      this.save()
    },
    save () {
      this.closable = false
      this.saveButtonLoading = true
      const api = this.isUpdate ? update() : add()
      api
        .complete((success) => {
          this.closable = true
          this.saveButtonLoading = false
          if (success) {
            this.$nextTick(() => (this.visible = false))
          }
        })
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved', new ProposalModel(resp.data))
        })
        .send(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
