/**
 * @author jiangxingshang
 * @date 2022/12/13
 */
import User from '../model/user'
export default class UserUpdate extends User {
  constructor (initData) {
    super(initData)
    this.appliedRoleIds = []
  }
}
