/**
 * 在编辑角色的时候，获取编辑角色的权限列表，根据{@link #applied}来判断该权限是否已经分配给当前编辑的角色了。
 * @author jiangxingshang
 * @date 2022/12/7
 */
import kit from '@/utils/kit'
import ProtectResource from '@/data/model/protect-resource'

export default class CurrentRoleProtectResource extends ProtectResource {
  constructor (initData) {
    super(initData)
    this.applied = false
    this.protectResourceItemValues = []

    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
