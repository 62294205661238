/**
 * @author jiangxingshang
 * @date 2023/3/8
 */
import kit from '@/utils/kit'

const mapping = {
  url: 'url',
  ui: 'ui',
  menu: 'menu'
}

const text = {}
text[mapping.url] = 'URL'
text[mapping.ui] = 'UI'
text[mapping.menu] = '菜单'


export default {
  mapping: mapping,
  text: text,
  options: kit.obj.toArray(text)
}
