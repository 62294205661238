export const ROUTE_HOME = 'home'
export const ROUTE_DASHBOARD = 'dashboard'
export const ROUTE_LOGIN = 'login'
export const ROUTE_404 = 'not-found'

export const ROUTE_CUSTOMER = 'customer'

export const ROUTE_USER = 'user'
export const ROUTE_ROLE = 'role'

export const ROUTE_PROPOSAL = 'proposal'
export const ROUTE_ADS = 'ads'
export const ROUTE_AWARDS_ITEMS = 'awards-items'
export const ROUTE_AWARDS = 'awards'
export const ROUTE_CUSTOMER_PRIZES = 'customer-prizes'
