/**
 * @author jiangxingshang
 * @date 2023/3/8
 */

import businessType from './business-type'
import pointHistoryType from './point-history-type'
import aiTaskExecImageStatus from './ai-task-exec-image-status'

function build (m) {
  return { text: m.text, options: m.options, colors: m.colors, ...m.mapping }
}

const constants = {
  businessType: build(businessType),
  pointHistoryType: build(pointHistoryType),
  aiTaskExecImageStatus: build(aiTaskExecImageStatus)
}


export default function (Vue) {
  Object.defineProperty(Vue.prototype, '$const', { get () { return constants } })
}
