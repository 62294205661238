/**
 * @author jiangxingshang
 * @date 2022/12/6
 */
import PageSize from './pagesize'

export default class AwardsQuery extends PageSize {
  constructor () {
    super()
    this.awardsItemsId = null
  }
}
