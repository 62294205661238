import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'

/**
 * @param userQuery {UserQuery}
 */
export const list = defineApi((config, customerPrizesQuery) => {
  config.params = customerPrizesQuery
  return http.get(path('/customer_prizes/list'), config)
})
