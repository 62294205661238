<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="toolbar">
      <a-button type="primary" @click="$refs.userEditor.open()">添加</a-button>
      <a-button @click="navRole">角色管理</a-button>
    </div>
    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline">
          <a-form-model-item label="姓名">
            <a-input v-model="searchForm.name" :allowClear="true"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="loadData">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="v, record">
        <a-button size="small" @click="$refs.userEditor.open(record)">编辑</a-button>
      </div>
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>

    <user-editor ref="userEditor" @saved="handleUserSave"></user-editor>
  </app-page>
</template>

<script>
import { ROUTE_USER, ROUTE_ROLE } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { list as listUser } from '@/http/api/user'
import UserQuery from '@/data/query/user'
import UserEditor from './comp/user-editor'
import kit from '@/utils/kit'
import PaginationMixin from '@/mixins/pagination'
import UserDto from '@/data/dto/user-dto'

export default {
  components: { UserEditor },
  data () {
    return {
      routeName: ROUTE_USER,
      dataColumns: [
        {
          title: '操作',
          width: 80,
          scopedSlots: { customRender: 'action' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '帐号',
          dataIndex: 'username'
        },
        {
          title: '创建日期',
          dataIndex: 'createDate'
        }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: new UserQuery(),
      userTypeMapping: {}
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      this.dataLoading = true
      listUser()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = kit.arr.newList(resp.data.records, UserDto)
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handleUserSave (user) {
      kit.arr.pushOrReplace(this.dataList, user)
    },
    navRole () {
      this.$router.push({ name: ROUTE_ROLE })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
