/**
 * @author zf
 * @date 2024/06/05
 */

import defineApi from '../define-api'
import http from '../application-api'
import path from '@/http/api/url-prefix'


export const listByProposalId = defineApi((config, proposalId) => {
  config.params = { proposalId: proposalId }
  http.get(path('/ads/list/by_proposal_id'), config)
})

export const getById = defineApi((config, id) => {
  config.params = { id: id }
  http.get(path('/ads/get/by_id'), config)
})

export const add = defineApi((config, ads) => {
  config.data = ads
  http.post(path('/ads/add'), config)
})

export const update = defineApi((config, ads) => {
  config.data = ads
  http.post(path('/ads/update'), config)
})

