<template>
  <drawer
      :visible.sync="visible"
      :closable="closable"
      :saveButtonLoading="saveButtonLoading"
      title="保护资源组编辑器"
      @save="onSave"
      @close="onClose"
      :width="800"
  >

    <a-form-model ref="form" :model="form" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item label="名称" class="app_required-input">
        <a-input v-model="form.name"/>
      </a-form-model-item>
      <a-form-model-item label="描述">
        <a-textarea v-model="form.description" :auto-size="{ minRows: 2, maxRows: 2 }" />
      </a-form-model-item>
      <a-form-model-item label="分组" class="app_required-input">
        <a-auto-complete
            v-model="form.groupName"
            :data-source="protectResourceGroupNameOptionsFilter"
            style="width: 100%;"
            @search="onProtectResourceGroupNameOptionSearch"
        />
      </a-form-model-item>
      <a-form-model-item label="是否可用">
        <a-switch v-model="form.enabled"></a-switch>
      </a-form-model-item>
    </a-form-model>

    <a-divider orientation="left">请选择系统保护资源</a-divider>
    <div class="resource-container">
      <div class="section-panel">
        <div class="panel-head">
          <div class="title">
            菜单
            <span class="text-muted font-mini" v-show="checkedMenuResources.length > 0">（已选 {{checkedMenuResources.length}} 个）</span>
          </div>
          <div class="toolbar">
            <a-button size="small" @click="handleOpenUIEditor('menu')">新增</a-button>
          </div>
        </div>
        <div class="panel-body">
          <ul class="ui-list">
            <li class="ui-list-item" :class="{'checked': item._checked}" v-for="item in menuResources" :key="item.id">
              <a-checkbox v-model="item._checked" style="margin-top: 2px;"></a-checkbox>
              <div class="main-content">
                <div class="title">
                  {{item.title}}
                  <span class="code text-muted font-mini">（{{item.code}}）</span>
                </div>
                <div class="desc text-muted font-mini" v-if="item.description">{{item.description}}</div>
              </div>
              <div class="buttons">
                <a-button size="small" type="link" @click="handleOpenUIEditor(item)">修改</a-button>
                <a-button size="small" type="link" @click="handleDeleteUI(item)" :loading="uiDeleting">删除</a-button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section-panel">
        <div class="panel-head">
          <div class="title">
            UI
            <span class="text-muted font-mini" v-show="checkedUiResources.length > 0">（已选 {{checkedUiResources.length}} 个）</span>
          </div>
          <div class="toolbar">
            <a-button size="small" @click="handleOpenUIEditor('ui')">新增</a-button>
          </div>
        </div>
        <div class="panel-body">
          <ul class="ui-list">
            <li class="ui-list-item" :class="{'checked': item._checked}" v-for="item in uiResources" :key="item.id">
              <a-checkbox v-model="item._checked" style="margin-top: 2px;"></a-checkbox>
              <div class="main-content">
                <div class="title">
                  {{item.title}}
                  <span class="code text-muted font-mini">（{{item.code}}）</span>
                </div>
                <div class="desc text-muted font-mini" v-if="item.description">{{item.description}}</div>
              </div>
              <div class="buttons">
                <a-button size="small" type="link" @click="handleOpenUIEditor(item)">修改</a-button>
                <a-button size="small" type="link" @click="handleDeleteUI(item)" :loading="uiDeleting">删除</a-button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section-panel">
        <div class="panel-head">
          <div class="title">URL</div>
          <div class="toolbar">
            <a-input v-model="apiSearchKeyword" :allow-clear="true" placeholder="请输入关键字搜索API" style="width: 250px;"></a-input>
          </div>
        </div>
        <div class="panel-body">
          <div class="ms-resource">

            <div class="group-panel">

              <div class="group-wrapper">
                <div class="group-item"
                     :class="{
                    'selected': selectedApiResourceGroup && selectedApiResourceGroup === g,
                    'has-checked': g.resources.filter(item => item._checked).length > 0,
                    'search-match': g._isSearchMatched
                    }"
                     v-for="g in this.apiResources"
                     :key="g.id"
                     @click="selectedApiResourceGroup = g"
                >
                  {{g.name}}
                  <span class="selected-total">{{g.resources.filter(item => item._checked).length}}</span>
                </div>
              </div>
            </div>

            <div class="resource-panel">
              <div v-if="selectedApiResourceGroup">
                <div style="margin: 10px 0;">
                  <a-checkbox v-model="selectedApiResourceGroup._checked" @change="onAvailableResourceCheckChanged($event, selectedApiResourceGroup, null)"></a-checkbox>
                  <span class="font-bold" style="margin-left: 10px;">全选 - {{selectedApiResourceGroup.name}}</span>
                </div>
                <div class="res-item res"
                     :class="{'search-match': r._isSearchMatched}"
                     v-for="r in selectedApiResourceGroup.resources"
                     :key="r.path">
                  <div>
                    <a-checkbox v-model="r._checked" @change="onAvailableResourceCheckChanged($event, selectedApiResourceGroup, r)"></a-checkbox>
                  </div>
                  <div class="res-content">
                    <div class="name">{{r.name}}</div>
                    <div v-show="r.desc" class="desc">{{r.desc}}</div>
                    <div class="path">{{r.path}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <protect-resource-ui-editor ref="uiEditor" @saved="handleUISaved"></protect-resource-ui-editor>

  </drawer>
</template>

<script>
import kit from '@/utils/kit'
import ProtectResourceUiEditor from './protect-resource-ui-editor'
import ProtectResourceUiModel from '@/data/model/protect-resource-ui'
import ProtectResourceModel from '@/data/model/protect-resource'
import {
  listAvailableResources,
  listGroupNames,
  deleteUi,
  updateProtectResource,
  addProtectResource,
  getProtectResourceItemsById
} from '@/http/api/protect-resource'
import ProtectResourceItemType from '@/constants/protect-resource-item-type'
import { debounce } from 'throttle-debounce'
import ProtectResourceUpdateDto from '@/data/dto/protect-resource-update'

export default {
  components: { ProtectResourceUiEditor },
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      form: new ProtectResourceModel(),
      protectResourceGroupNameOptions: [],
      protectResourceGroupNameOptionsFilter: [],
      uiResources: [],
      menuResources: [],
      apiResources: [],
      selectedApiResourceGroup: null,
      apiSearchKeyword: null,
      uiDeleting: false
    }
  },
  watch: {
    apiSearchKeyword () {
      this.handleApiResourceSearchMatch(this.apiSearchKeyword)
    }
  },
  computed: {
    checkedUiResources () {
      return this.uiResources.filter(item => item._checked)
    },
    checkedMenuResources () {
      return this.menuResources.filter(item => item._checked)
    },
    checkedApiResources () {
      const arr = []
      for (const r of this.allApiResources) {
        if (r._checked) {
          arr.push(r)
        }
      }
      return arr
    },
    allApiResourceGroups () {
      const arr = []
      for (const g of this.apiResources) {
        arr.push(g)
      }
      return arr
    },
    allApiResources () {
      const arr = []
      for (const g of this.allApiResourceGroups) {
        for (const r of g.resources) {
          arr.push(r)
        }
      }
      return arr
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.form = new ProtectResourceModel(model)
      if (this.uiResources.length === 0 || this.menuResources.length === 0 || this.apiResources.length === 0) {
        listAvailableResources()
          .success(resp => {
            // UI资源
            let uiArr = []
            if (resp.data.ui) {
              uiArr = kit.arr.newList(resp.data.ui, ProtectResourceUiModel, m => {
                m._checked = false
              })
            }
            this.uiResources = uiArr

            // 菜单资源
            let menuArr = []
            if (resp.data.menu) {
              menuArr = kit.arr.newList(resp.data.menu, ProtectResourceUiModel, m => {
                m._checked = false
              })
            }
            this.menuResources = menuArr

            // API资源
            for (const g of resp.data.url) {
              g._isSearchMatched = false
              g._checked = false
              for (const r of g.resources) {
                r._isSearchMatched = false
                r._checked = false
              }
            }
            this.apiResources = resp.data.url

            if (model) {
              this.initProtectResourceItem()
            }
          })
          .send()
      } else if (model) {
        this.initProtectResourceItem()
      }
    },
    initProtectResourceItem () {
      getProtectResourceItemsById()
        .success(resp => {
          const apiResMap = {}
          for (const api of this.allApiResources) {
            apiResMap[api.path] = api
          }
          for (const item of resp.data) {
            const value = item.value
            const type = item.type
            if (type === ProtectResourceItemType.mapping.url) {
              if (apiResMap[value]) {
                apiResMap[value]._checked = true
              }
            } else if (type === ProtectResourceItemType.mapping.ui) {
              const found = this.uiResources.find(ui => ui.code === value)
              if (found) found._checked = true
            } else if (type === ProtectResourceItemType.mapping.menu) {
              const found = this.menuResources.find(ui => ui.code === value)
              if (found) found._checked = true
            }
          }
        })
        .send(this.form.id)
    },
    onClose () {
      for (const item of this.uiResources) {
        item._checked = false
      }
      for (const item of this.menuResources) {
        item._checked = false
      }
      for (const item of this.allApiResources) {
        item._checked = false
      }
    },
    onSave () {
      this.save()
    },
    save () {
      if (this.checkedApiResources.length === 0 && this.checkedUiResources.length === 0 && this.checkedMenuResources.length === 0) {
        this.$message.warning('请至少选择一个保护资源。')
      } else {
        const form = new ProtectResourceUpdateDto(this.form)
        for (const apiRes of this.checkedApiResources) {
          form.protectResourceItems.push({ value: apiRes.path, type: ProtectResourceItemType.mapping.url })
        }
        for (const uiRes of this.checkedUiResources) {
          form.protectResourceItems.push({ value: uiRes.code, type: ProtectResourceItemType.mapping.ui })
        }
        for (const menuRes of this.checkedMenuResources) {
          form.protectResourceItems.push({ value: menuRes.code, type: ProtectResourceItemType.mapping.menu })
        }
        const api = this.form.id ? updateProtectResource : addProtectResource
        this.saveButtonLoading = true
        api()
          .complete(() => (this.saveButtonLoading = false))
          .success(resp => {
            this.$message.success('保存成功。')
            this.$emit('saved')
            this.$nextTick(() => {
              this.visible = false
            })
          })
          .send(form)
      }
    },
    handleOpenUIEditor (modelOrType) {
      let model = null
      let type = null
      if (typeof modelOrType === 'string') {
        type = modelOrType
      } else {
        model = modelOrType
        type = model.type
      }
      this.$refs.uiEditor.open(model, type)
    },
    handleUISaved (protectResourceUi) {
      const ui = new ProtectResourceUiModel(protectResourceUi)
      ui._checked = false
      const arr = protectResourceUi.type === ProtectResourceItemType.mapping.ui ? this.uiResources : this.menuResources
      kit.arr.pushOrReplace(arr, ui)
      this.initProtectResourceItem()
    },
    handleDeleteUI (ui) {
      this.$confirm({
        title: '确认',
        content: `确定要删除【${ui.title}】吗？`,
        onOk: () => {
          this.uiDeleting = true
          deleteUi()
            .complete(() => (this.uiDeleting = false))
            .success(() => {
              const arr = ui.type === ProtectResourceItemType.mapping.ui ? this.uiResources : this.menuResources
              kit.arr.removeItem(arr, ui)
            })
            .send(ui.id)
        }
      })
    },
    onAvailableResourceCheckChanged (e, group, res) {
      if (res) {
        let allChecked = true
        for (const r of group.resources) {
          if (!r._checked) {
            allChecked = false
            break
          }
        }
        group._checked = allChecked
      } else {
        for (const r of group.resources) {
          r._checked = e.target.checked
        }
      }
    },
    onProtectResourceGroupNameOptionSearch (searchText) {
      if (searchText) {
        const arr = this.protectResourceGroupNameOptions.filter(item => (item.includes(searchText)))
        if (arr.length > 0) {
          this.protectResourceGroupNameOptionsFilter = arr
        } else {
          this.protectResourceGroupNameOptionsFilter = [searchText]
        }
      } else {
        this.protectResourceGroupNameOptionsFilter = this.protectResourceGroupNameOptions
      }
    }
  },
  mounted () {
    listGroupNames()
      .success(resp => {
        this.protectResourceGroupNameOptions = resp.data
        this.protectResourceGroupNameOptionsFilter = resp.data
      })
      .send()

    this.handleApiResourceSearchMatch = debounce(300, keyword => {
      for (const g of this.allApiResourceGroups) {
        g._isSearchMatched = false
        for (const res of g.resources) {
          res._isSearchMatched = keyword != null && keyword.length > 0 && (res.name.includes(keyword) || res.path.includes(keyword))
          if (res._isSearchMatched) {
            g._isSearchMatched = true
          }
        }
      }
    })
  }
}
</script>

<style lang="less" scoped>
.resource-container {
}

.section-panel + .section-panel {
  margin-top: 40px;
}
.section-panel {
  display: flex;
  flex-direction: column;
  border: solid 1px #eee;
  .panel-head {
    display: flex;
    align-items: center;
    padding: 12px 10px;
    background-color: #f2f2f2;
    border-bottom: solid 1px #eee;
    .title {
      flex: 1;
      font-weight: bold;
    }
  }

  .panel-body {
    overflow-y: auto;
    min-height: 100px;
  }
}

.ui-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .ui-list-item {
    display: flex;
    padding: 14px 10px;

    &:not(:first-of-type) {
      border-top: solid 1px #eee;
    }

    &.checked .title {
      color: #0572b6;
    }

    .main-content {
      flex: 1;
      margin: 0 10px;

      .desc {
        padding-left: 15px;
      }
    }

    .buttons {
      visibility: hidden;
    }

    &:hover {
      background-color: #f9f9f9;
      .buttons {
        visibility: visible;
      }
    }
  }
}

.ms-resource {
  display: flex;

  .group-panel {
    width: 330px;
    margin-right: 10px;
    border-right: solid 1px #eee;

    .group-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .group-item {
        position: relative;
        padding: 8px;
        font-size: 13px;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
        &.selected {
          background-color: #3c6d9f !important;
          color: #fff;
        }

        .selected-total {
          visibility: hidden;
          font-weight: bold;
          color: #d97e7b;
          margin-left: 7px;
          font-size: 14px;
        }
        &.has-checked .selected-total {
          visibility: visible;
        }
        &.has-checked:not(.selected) {
          color: #cb5f5c;
        }
        &.search-match {
          color: #2f790e !important;
        }
        &.selected.search-match {
          color: #ffffff !important;
        }
      }
    }
  }

  .resource-panel {
    flex: 1;
    padding-bottom: 10px;
    .res-item.group-head {
      padding: 8px 0;
      margin: 8px 0;
      border-bottom: dashed 1px #d98c8c;
      font-weight: bold;
    }

    .res-item.res + .res-item.res {
      margin-top: 8px;
    }
    .res-item.res {
      display: flex;
      .res-content {
        margin-left: 10px;
      }
      .name {
        color: #414040;
        font-size: 12px;
      }
      .desc {
        color: #a6a4a4;
        font-size: 12px;
      }
      .path {
        font-size: 12px;
        color: #ddd;
      }
      &.search-match .name {
        color: #2f790e !important;
      }
    }
  }
}
</style>
