/**
 * @author zf
 * @date 2024/06/07
 */
import ShopifyPage from './shopifypage'
import kit from '@/utils/kit'
export default class ShopifyDiscountQuery extends ShopifyPage {
  constructor (initData) {
    super()
    this.awardsItemsId = null
    this.nextPage = true
    this.previousPage = false
    this.type = null
    this.createDateStart = null
    this.createDateEnd = null
    this.createDate = []
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }

  buildQueryDate () {
    const q = Object.assign({}, this)
    if (q.createDate.length === 2) {
      q.createDateStart = q.createDate[0]
      q.createDateEnd = q.createDate[1]
    }
    delete q.createDate
    return q
  }
}
