import {
  ROUTE_USER,
  ROUTE_PROPOSAL,
  ROUTE_CUSTOMER_PRIZES,
  ROUTE_CUSTOMER
} from '@/router/router-constants'

const menu = [
  {
    id: 'customer',
    title: '业务',
    menus: [
      { id: ROUTE_PROPOSAL, title: '方案设置' },
      { id: ROUTE_CUSTOMER_PRIZES, title: '客户抽奖记录' },
      { id: ROUTE_CUSTOMER, title: '客户' }
    ]
  },
  {
    id: 'manage',
    title: '维护',
    menus: [
      { id: ROUTE_USER, title: '用户管理' }
    ]
  }
]

export default menu
