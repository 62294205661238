<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="$refs.editor.open($route.params.id)">添加</a-button>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="$refs.editor.open($route.params.id,row)">编辑</a-button>
        <a-button size="small" type="primary" v-if="row.hasAwards" @click="$router.push({name: awardsRouteName, params: {itemsId: row.id}})" >奖品管理</a-button>
      </div>
      <div slot="probability" slot-scope="probability">
        {{probability}}%
      </div>
      <status slot="enabled" slot-scope="enabled" :status="enabled" enabled-text="使用中" disabled-text="停用"></status>
      <status slot="hasAwards" slot-scope="hasAwards" :status="hasAwards" enabled-text="有" disabled-text="无"></status>
    </a-table>

    <awards-items-editor ref="editor" @saved="handleAwardsItemsSave"></awards-items-editor>
  </app-page>
</template>

<script>
import { ROUTE_AWARDS_ITEMS, ROUTE_AWARDS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { listByProposalId } from '@/http/api/awards-items'
import AwardsItemsEditor from './comp/awards-items-editor'
import kit from '@/utils/kit'

export default {
  components: { AwardsItemsEditor },
  mixins: [AntdTableHeightMixin],
  data () {
    return {
      routeName: ROUTE_AWARDS_ITEMS,
      awardsRouteName: ROUTE_AWARDS,
      pageLoading: false,
      dataColumns: [
        { title: '操作', width: 150, scopedSlots: { customRender: 'action' } },
        { title: '奖项名称', dataIndex: 'title' },
        { title: '权重', width: 120, dataIndex: 'probability', scopedSlots: { customRender: 'probability' } },
        // { title: '奖项color', width: 120, dataIndex: 'color' },
        // { title: '奖项背景色', width: 120, dataIndex: 'bg' },
        { title: '排序', width: 60, dataIndex: 'orders' },
        { title: '说明', dataIndex: 'meno' },
        { title: '是否可用', width: 120, dataIndex: 'enabled', scopedSlots: { customRender: 'enabled' } },
        { title: '是否有奖品', width: 120, dataIndex: 'hasAwards', scopedSlots: { customRender: 'hasAwards' } },
        { title: '创建时间', width: 120, dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false
    }
  },
  methods: {
    loadData () {
      this.dataLoading = true
      listByProposalId()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.$route.params.id)
    },
    handleAwardsItemsSave (p) {
      kit.arr.pushOrReplace(this.dataList, p)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
