<template>
  <a-modal
      title="修改密码"
      :visible="isVisible"
      :confirm-loading="confirmLoading"
      :closable="closable"
      :maskClosable="closable"
      :keyboard="closable"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <a-form-model ref="form" :model="form" :rules="formRules" :label-col="{span: 4}" :wrapper-col="{span: 20}">
      <a-form-model-item has-feedback label="旧密码" prop="oldPassword">
        <a-input v-model="form.oldPassword" type="password" autocomplete="off" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="新密码" prop="newPassword">
        <a-input v-model="form.newPassword" type="password" autocomplete="off" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="确认密码" prop="confirmPassword">
        <a-input v-model="form.confirmPassword" type="password" autocomplete="off" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updatePassword, logout } from '@/http/api/user'
import { ROUTE_LOGIN } from '@/router/router-constants'

export default {
  props: {
    visible: {
      type: Boolean, default: false
    }
  },
  data () {
    return {
      confirmLoading: false,
      closable: true,
      form: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
      },
      formRules: {
        oldPassword: [
          { required: true, message: '旧密码不能为空。', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '新密码不能为空。', trigger: 'blur' },
          { min: 6, max: 20, message: '新密码长度必须为6～20位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '确认密码不能为空。', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.newPassword) {
                callback(new Error('两次输入的密码不相同。'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (v) {
        this.$emit('update:visible', v)
      }
    }
  },
  watch: {
    isVisible () {
      if (!this.isVisible) {
        this.$refs.form.resetFields()
      }
    }
  },
  methods: {
    handleOk () {
      this.$refs.form.validate(valid => {
        if (!valid) return false
        this.confirmLoading = true
        this.closable = false
        updatePassword()
          .complete(success => {
            this.confirmLoading = false
            this.closable = true
            if (success) {
              this.isVisible = false
              this.$confirm({
                title: '确认？',
                content: '密码修改成功，是否重新登录？',
                onOk: () => {
                  this.logout()
                }
              })
            }
          })
          .send(this.form.oldPassword, this.form.newPassword)
      })
    },
    logout () {
      logout()
        .success(() => {
          this.$router.push({ name: ROUTE_LOGIN })
        })
        .send()
    },
    handleCancel () {
      if (this.closable) {
        this.isVisible = false
      }
    }
  }
}
</script>

<style scoped>

</style>
