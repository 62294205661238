/**
 * @author zf
 * @date 2024/06/05
 */
import kit from '@/utils/kit'

export default class AwardsItems {
  constructor (initData) {
    this.id = null
    this.title = null
    this.proposalId = null
    this.probability = null
    this.color = null
    this.bg = null
    this.orders = null
    this.meno = null
    this.createTime = null
    this.createUserId = null
    this.enabled = true
    this.hasAwards = true
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
