/**
 * @author jiangxingshang
 * @date 2022/12/6
 */
import PageSize from './pagesize'

export default class UserQuery extends PageSize {
  constructor () {
    super()
    /** 姓名模糊查询 */
    this.name = null
    this.qjId = null
  }
}
