<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
      <image-modal ref="imageModal"></image-modal>
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { userStore } from '@/store'
import Vue from 'vue'

export default {
  data () {
    return {
      locale: zhCN
    }
  },
  computed: {
    isLogin () {
      return userStore.get('isLogin')
    }
  },
  watch: {
    isLogin: {
      handler: function (flag) {
      },
      immediate: true
    }
  },
  mounted () {
    const $imageModal = this.$refs.imageModal
    Vue.use({
      install (Vue) {
        Object.defineProperties(Vue.prototype, {
          $imageModal: {
            get () {
              return $imageModal
            }
          }
        })
      }
    })
  }
}
</script>
<style lang="less">
@import "assets/style/hack";
@import "assets/style/antd-hack";
@import "assets/style/commons";
@import "assets/style/list-group";
#app {
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
</style>
