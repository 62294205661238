import { render, staticRenderFns } from "./awards-bind.vue?vue&type=template&id=0a53a3d0&scoped=true&"
import script from "./awards-bind.vue?vue&type=script&lang=js&"
export * from "./awards-bind.vue?vue&type=script&lang=js&"
import style0 from "./awards-bind.vue?vue&type=style&index=0&id=0a53a3d0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a53a3d0",
  null
  
)

export default component.exports