/**
 * 页面的标题默认在路由配置中设置，有时候我们需要动态的设置页面标题，我们将动态的标题
 * 保存在状态中，在多个页面组件中共享。
 * @see components/layout/page
 */
import Vue from 'vue'
import StoreModule from './module'
import { appStore } from '@/store'

export default {
  namespaced: true,
  state: {
    paths: [],
    title: null,
    titleMap: {}
  },
  mutations: {
    setTitleMap (state, data) {
      Vue.set(state.titleMap, data.name, data.title)
    },
    setTitle (state, title) {
      state.title = title
    }
  }
}

export const PAGE_TITLE_MODULE = 'pageTitle'
export class PageTitleStore extends StoreModule {
  constructor (store) {
    super(PAGE_TITLE_MODULE, store)
  }

  setTitleForRouter (routeName, title) {
    this.commit('setTitleMap', { name: routeName, title: title })
  }

  setTitle (title) {
    this.commit('setTitle', title)
    document.title = title || appStore.state.name
  }

  getTitleForRouter (routeName) {
    const title = this.state.titleMap[routeName]
    if (title && title.length > 15) {
      return title.substring(0, 15) + '...'
    } else {
      return title
    }
  }
}
