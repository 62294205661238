<template>
  <div :class="type" class="tag">
    <div class="content">
      <slot></slot>
    </div>
    <a-icon
        v-if="closable"
        type="close"
        style="cursor: pointer; font-size: 12px; margin-left: 5px;"
        @click="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'tag',
  props: {
    type: { type: String, default: 'default' },
    closable: { type: Boolean, default: true }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.tag + .tag {
  margin-left: 10px;
}
.tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  padding: 1px 6px;
  border-radius: 3px;
  border: solid 1px #ddd;
  &.default {
    background-color: #fff;
    color: #5b5b5b;
    border-color: #5b5b5b;
  }
  &.primary {
    background-color: #e7f7ff;
    color: #3d8acf;
    border-color: #3d8acf;
  }
  &.danger {
    background-color: #ffe7ed;
    color: #80181b;
    border-color: #80181b;
  }
  &.success {
    background-color: #e7ffee;
    color: #228018;
    border-color: #228018;
  }
  &.warn {
    background-color: #fff3e7;
    color: #805d18;
    border-color: #805d18;
  }
}
.content {
  flex: 1;
}
</style>
