import StoreModule from './module'
import kit from '@/utils/kit'
import { appStore } from '@/store'

export default {
  namespaced: true,
  state: {
    generalSetting: {
      shopName: null,
      logoUrl: null,
      domain: null,
      currency: null
    }
  },
  mutations: {
    updateSetting (state, setting) {
      const tmp = kit.obj.deepCopy(setting)
      Object.assign(state.generalSetting, tmp)
    }
  }
}

export const SETTING_MODULE = 'setting'
export class SettingStore extends StoreModule {
  constructor (store) {
    super(SETTING_MODULE, store)
  }

  updateSetting (setting) {
    const appInfo = Object.assign({}, appStore.state)
    appInfo.logoUrl = setting.logoUrl
    appInfo.name = setting.shopName
    appStore.setAppInfo(appInfo)
    this.commit('updateSetting', setting)
  }
}
