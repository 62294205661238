import StoreModule from './module'

export default {
  namespaced: true,
  state: {
    version: 'Dev',
    name: 'Shopify Lottery',
    logoUrl: null
  },
  mutations: {
    setAppInfo (state, data) {
      Object.assign(state, data)
    }
  }
}

export const APP_MODULE = 'app'
export class AppStore extends StoreModule {
  constructor (store) {
    super(APP_MODULE, store)
  }

  setAppInfo (appInfo) {
    this.commit('setAppInfo', appInfo)
  }
}
