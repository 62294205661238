<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline">
          <a-form-model-item label="券码">
            <a-input v-model="searchForm.code" :allowClear="true"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="loadData">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_CUSTOMER_PRIZES } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { list } from '@/http/api/customer-prizes'
import CustomerPrizesQuery from '@/data/query/customerprizes'

export default {
  data () {
    return {
      routeName: ROUTE_CUSTOMER_PRIZES,
      dataColumns: [
        { title: '用户', dataIndex: 'customerId' },
        { title: '抽奖日期', dataIndex: 'createTime' },
        { title: 'code', dataIndex: 'code' },
        { title: 'title', dataIndex: 'awardsMeno' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: new CustomerPrizesQuery()
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
