/**
 * antd-table要固定表头，需要给scroll="{ y: number }"一个固定值，本mixin需要配合
 * app-page.vue使用，从app-page.vue中可以得到contentHeight的实际值，然后减去55（表头高度）
 * 便是表内容高度。
 * <a-table :scroll="{ y: antdTableY }"></a-table>
 * @author jiangxingshang
 * @date 2020/12/12
 */
export default {
  data () {
    return {
      contentHeight: null
    }
  },
  computed: {
    antdTableY () {
      return (this.contentHeight || 0) - 55
    }
  }
}
