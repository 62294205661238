<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="奖项编辑"
    width="50%"
    @save="onSave"
    @close="onClose"
  >
    <a-form-model ref="form" :model="form" :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="奖项名称" class="app_required-input">
        <a-input v-model="form.title"/>
      </a-form-model-item>
      <a-form-model-item label="权重" class="app_required-input">
        <a-input-number
          :min="0"
          :max="100"
          v-model="form.probability"
          :formatter="value => `${value}%`"
          :parser="value => value.replace('%', '')"
        />
      </a-form-model-item>
<!--      <a-form-model-item label="奖项color" class="app_required-input">-->
<!--        <a-input v-model="form.color"/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="奖项背景" class="app_required-input">-->
<!--        <a-input v-model="form.bg"/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="排序" class="app_required-input">
        <a-input-number v-model="form.orders"/>
      </a-form-model-item>
      <a-form-model-item label="抽中奖品描述" class="app_required-input">
        <a-textarea
          v-model="form.meno"
          placeholder="Controlled autosize"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
      <a-form-model-item label="是否可用">
        <a-switch v-model="form.enabled"></a-switch>
      </a-form-model-item>
      <a-form-model-item label="是否有奖品">
        <a-switch v-model="form.hasAwards"></a-switch>
      </a-form-model-item>
    </a-form-model>
  </drawer>
</template>url

<script>
import { add, update } from '@/http/api/awards-items'
import AwardsItems from '@/data/model/awards-items'

export default {
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      model: null,
      form: new AwardsItems()
    }
  },
  watch: {
  },
  computed: {
    isUpdate () {
      return !!this.form.id
    },
    isHandleEditable () {
      return !this.isUpdate
    }
  },
  methods: {
    open (proposalId, model) {
      this.visible = true
      if (model) {
        this.form = new AwardsItems(model)
      } else {}
      this.form.proposalId = proposalId
    },
    onClose () {
      this.form = new AwardsItems()
    },
    onSave () {
      this.save()
    },
    save () {
      this.closable = false
      this.saveButtonLoading = true
      const api = this.isUpdate ? update() : add()
      api
        .complete((success) => {
          this.closable = true
          this.saveButtonLoading = false
          if (success) {
            this.$nextTick(() => (this.visible = false))
          }
        })
        .success(resp => {
          this.$message.success('保存成功。')
          this.$emit('saved', new AwardsItems(resp.data))
        })
        .send(this.form)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
