<template>
  <li class="context-menu-item"
      :class="{disabled: disabled}"
      v-show="visible"
      @click="handleClick"
  >
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: 'context-menu-item',
  props: {
    name: { type: String },
    visible: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    handleClick () {
      if (!this.disabled) {
        this.$parent.close()
        this.$parent.$emit('menuItemClick', this.name, this.$parent.$data.menuData)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.context-menu-item {
  display: block;
  padding: 4px 10px;
  font-size: 13px;
  color: #2a2a2a;
  cursor: pointer;
  &:hover:not(.disabled) {
    background-color: #f1f1f1;
  }
  &.disabled {
    cursor: not-allowed;
    color: #c8c8c8 !important;
  }
}
.context-menu-item + .context-menu-item {
  border-top: solid 1px #e9e9e9;
}
</style>
