/**
 * @author zf
 * @date 2024/06/04
 */
import kit from '@/utils/kit'

export default class Proposal {
  constructor (initData) {
    this.id = null
    this.name = null
    this.startDate = null
    this.endDate = null
    this.current = false
    this.createTime = null
    this.createUserId = null
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
