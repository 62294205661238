<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight">
    <div slot="top" class="search-form">
      <div class="form">
        <a-form-model layout="inline">
          <a-form-model-item label="用户">
            <a-input v-model="searchForm.shopId" :allowClear="true"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册日期">
            <a-range-picker v-model="searchForm.dates" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 200px;"/>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="buttons">
        <a-button @click="loadData">查询</a-button>
      </div>
    </div>

    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
    </a-table>

    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
  </app-page>
</template>

<script>
import { ROUTE_CUSTOMER } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import { list } from '@/http/api/customer'


export default {
  data () {
    return {
      routeName: ROUTE_CUSTOMER,
      dataColumns: [
        { title: '用户', dataIndex: 'shopId' },
        { title: 'handle', dataIndex: 'handle' },
        { title: '注册日期', dataIndex: 'createDate' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: {
        shopId: null,
        dates: []
      }
    }
  },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  methods: {
    loadData () {
      const p = this.buildRequestParams(this.searchForm)
      if (p.dates.length === 2) {
        p.startDate = p.dates[0]
        p.endDate = p.dates[1]
      }
      delete p.dates
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
