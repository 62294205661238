<template>
  <a-layout id="home">
    <app-header class="header"></app-header>
    <a-layout>
      <a-layout-sider width="200" style="background: #fff">
        <app-menu></app-menu>
      </a-layout-sider>
      <a-layout id="app-body-container" class="app-content">
        <router-view></router-view>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import AppMenu from '@/layout/app-menu'
import AppHeader from '@/layout/app-header'

export default {
  components: { AppMenu, AppHeader },
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
#home {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}
.app-content {
  padding: 0 12px 0 12px;
  position: relative !important;
}
</style>
