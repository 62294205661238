<template>
  <app-page :route-name="routeName" :content-height.sync="contentHeight" :page-loading="pageLoading">

    <a-button slot="toolbar" @click="$refs.editor.open($route.params.itemsId)">绑定奖品</a-button>
    <a-table
        slot="center"
        size="middle"
        rowKey="id"
        :columns="dataColumns"
        :data-source="dataList"
        :pagination="false"
        :scroll="{ y: antdTableY }"
        :loading="dataLoading"
    >
      <div slot="action" slot-scope="row">
        <a-button size="small" @click="handleDeleteAwards(row)">解绑</a-button>
      </div>
      <status slot="used" slot-scope="used" :status="used" enabled-text="已使用" disabled-text="未用"></status>
    </a-table>
    <div slot="bottom">
      <pagination :total="page.total" :start.sync="page.start" :limit.sync="page.limit"></pagination>
    </div>
    <awards-bind ref="editor" @panelClose="handleBinderClose"></awards-bind>
  </app-page>
</template>

<script>
import { ROUTE_AWARDS } from '@/router/router-constants'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import { list, delAwards } from '@/http/api/awards'
import AwardsQuery from '@/data/query/awards'
import AwardsBind from './comp/awards-bind'
import kit from '@/utils/kit'
import PaginationMixin from '@/mixins/pagination'

export default {
  components: { AwardsBind },
  mixins: [AntdTableHeightMixin, PaginationMixin],
  data () {
    return {
      routeName: ROUTE_AWARDS,
      pageLoading: false,
      dataColumns: [
        { title: '操作', width: 80, scopedSlots: { customRender: 'action' } },
        { title: '券码', width: 120, dataIndex: 'code' },
        { title: '标题', dataIndex: 'meno' },
        { title: '是否已用', width: 120, dataIndex: 'used', scopedSlots: { customRender: 'used' } },
        { title: 'code id', width: 120, dataIndex: 'discountCodeId' },
        { title: '生效日期', width: 120, dataIndex: 'startsAt' },
        { title: '结束日期', width: 120, dataIndex: 'endsAt' },
        { title: '绑定时间', width: 120, dataIndex: 'createTime' }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: new AwardsQuery()
    }
  },
  methods: {
    loadData () {
      this.searchForm.awardsItemsId = this.$route.params.itemsId
      const p = this.buildRequestParams(this.searchForm)
      this.dataLoading = true
      list()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          this.dataList = resp.data.records
          this.page.total = resp.data.total
        })
        .send(p)
    },
    handleDeleteAwards (awards) {
      this.$confirm({
        title: '确认',
        content: `确定要解绑【${awards.code}】吗？`,
        onOk: () => {
          this.dataLoading = true
          delAwards()
            .complete(() => (this.dataLoading = false))
            .success(() => {
              this.$message.success('解绑成功。')
              kit.arr.removeItem(this.dataList, awards)
            })
            .send(awards.id)
        }
      })
    },
    handleBinderClose (saveSuccess) {
      if (saveSuccess === true) {
        this.$message.success('绑定成功。')
        this.loadData()
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>

</style>
