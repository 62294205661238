<template>
  <drawer
    :visible.sync="visible"
    :closable="closable"
    :saveButtonLoading="saveButtonLoading"
    title="绑定奖品"
    width="90%"
    saveButtonText="绑定所选"
    @save="onSave"
    @close="onClose"
  >
    <div class="wrapper">
      <div class="top">
        <a-form-model layout="inline">
          <a-form-model-item label="类型">
            <a-select v-model="searchForm.type" :allowClear="true" style="width: 120px;">
              <a-select-option v-for="item of this.dataTypes" :key="item.id">{{item.id}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="创建日期">
            <a-range-picker v-model="searchForm.createDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 210px;"/>
          </a-form-model-item>


          <a-button @click="search('search')">查询</a-button>
        </a-form-model>
      </div>

      <div class="tool-bar">
        <a-button @click="previousPage" :disabled="!pageInfo.hasPreviousPage">上一页</a-button>
        <a-button @click="nextPage" :disabled="!pageInfo.hasNextPage">下一页</a-button>
      </div>

      <div class="bottom">
        <a-table
          slot="center"
          size="middle"
          rowKey="discountCodeId"
          :columns="dataColumns"
          :data-source="dataList"
          :pagination="false"
          :scroll="{ y: antdTableY }"
          :loading="dataLoading"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
        >
          <status slot="enabled" slot-scope="enabled" :status="enabled" enabled-text="已使用" disabled-text="未用"></status>
          <status slot="bind" slot-scope="bind" :status="bind" enabled-text="已绑定" disabled-text="未绑定"></status>
        </a-table>
      </div>
    </div>
  </drawer>
</template>

<script>
import { listFromShopify, add } from '@/http/api/awards'
import ShopifyDiscountQuery from '@/data/query/shopifydiscount'
import ShopifyPage from '@/data/query/shopifypage'
import AntdTableHeightMixin from '@/mixins/antd-table-height'
import PaginationMixin from '@/mixins/pagination'
import moment from 'moment'
export default {
  mixins: [AntdTableHeightMixin, PaginationMixin],
  data () {
    return {
      visible: false,
      closable: true,
      saveButtonLoading: false,
      pageLoading: false,
      dataColumns: [
        { title: '券码', dataIndex: 'code' },
        { title: '标题', dataIndex: 'meno' },
        { title: '是否已用', width: 120, dataIndex: 'used', scopedSlots: { customRender: 'used' } },
        { title: 'code id', width: 120, dataIndex: 'discountCodeId' },
        { title: '生效日期', width: 120, dataIndex: 'startsAt' },
        { title: '结束日期', width: 120, dataIndex: 'endsAt' },
        { title: '是否绑定', width: 120, dataIndex: 'bind', scopedSlots: { customRender: 'bind' } }
      ],
      dataList: [],
      dataLoading: false,
      searchForm: new ShopifyDiscountQuery(),
      pageInfo: new ShopifyPage(),
      saveSuccess: null, // true表示保存成功了，false表示保存失败，null表示没有操作过保存
      selectedRows: [],
      selectedRowKeys: [],
      dataTypes: [
        { id: 'bogo', name: '买一送一' },
        { id: 'fixed_amount', name: '固定金额' },
        { id: 'free_shipping', name: '免运费' },
        { id: 'percentage', name: '百分比' }
      ]
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    moment,
    open (awardsItemsId) {
      this.visible = true
      this.searchForm = new ShopifyDiscountQuery()
      this.searchForm.awardsItemsId = awardsItemsId
      this.dataList = []
      this.selectedRows = []
      this.saveSuccess = null
    },
    onClose () {
      this.dataList = []
      this.selectedRows = []
      let success = this.saveSuccess
      this.saveSuccess = null
      this.$emit('panelClose', success)
    },
    onSave () {
      if (this.selectedRows.length < 1) {
        this.$message.error('请选择要绑定的券后再操作。')
        return
      }
      this.$confirm({
        title: '确认',
        content: `确定要绑定所选的 ${this.selectedRows.length} 行数据吗？`,
        onOk: () => {
          let list = []
          for (let item of this.selectedRows) {
            let awards = {
              code: item.code,
              discountCodeId: item.discountCodeId,
              startsAt: item.startsAt,
              endsAt: item.endsAt,
              meno: item.meno,
              used: item.used,
              awardsItemsId: this.searchForm.awardsItemsId,
              bind: item.bind
            }
            list.push(awards)
          }
          this.pageLoading = true
          add()
            .complete((success) => {
              this.saveSuccess = success
              this.closable = true
              this.saveButtonLoading = false
            })
            .success(resp => {
              let map = resp.data
              let content = ''
              let successTotal = 0
              let errorTotal = 0
              for (let discountCodeId of Object.keys(map)) {
                let val = map[discountCodeId]
                let awards = this.dataList.find(awards => awards.discountCodeId === discountCodeId) || {}
                if (val.id) {
                  content += `<p>${discountCodeId}：成功</p>`
                  successTotal++
                  awards.bind = true
                } else {
                  content += `<p>${discountCodeId}：${val}</p>`
                  errorTotal++
                  awards.bind = false
                }
              }
              content =
                '<div style="max-height: 200px; overflow-y: auto;">' +
                `<p>成功${successTotal}个，失败${errorTotal}个。</p>` +
                content +
                '</div>'

              this.$info({
                title: '保存结果',
                content: h => {
                  return h('div', { domProps: { innerHTML: content } })
                }
              })
            })
            .send(list)
        }
      })
      this.closable = false
      this.saveButtonLoading = true
    },
    search (type) {
      if (type === 'search') {
        this.searchForm.hasNextPage = false
        this.searchForm.hasPreviousPage = false
        this.searchForm.startCursor = null
        this.searchForm.endCursor = null
      }
      const p = this.searchForm.buildQueryDate()
      this.dataLoading = true
      listFromShopify()
        .complete(() => (this.dataLoading = false))
        .success(resp => {
          console.log(resp.data)
          this.dataList = resp.data.discountList
          this.pageInfo = resp.data.pageInfo
          console.log(this.pageInfo)
        })
        .send(p)
    },
    nextPage () {
      this.searchForm.nextPage = true
      this.searchForm.previousPage = false
      this.searchForm.startCursor = this.pageInfo.startCursor
      this.searchForm.endCursor = this.pageInfo.endCursor
      this.searchForm.hasNextPage = this.pageInfo.hasNextPage
      this.searchForm.hasPreviousPage = this.pageInfo.hasPreviousPage
      this.search()
    },
    previousPage () {
      this.searchForm.previousPage = true
      this.searchForm.nextPage = false
      this.searchForm.startCursor = this.pageInfo.startCursor
      this.searchForm.endCursor = this.pageInfo.endCursor
      this.searchForm.hasNextPage = this.pageInfo.hasNextPage
      this.searchForm.hasPreviousPage = this.pageInfo.hasPreviousPage
      this.search()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      console.log(selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    .bottom {
      flex: 1;
      overflow-y: auto;
      margin-top: 20px;
    }
  }
  .search-bar {
    padding-top: 20px;
    border-top: solid 1px #d4d4d4;
  }
  .tool-bar {
    padding-top: 10px;
    text-align: right;
  }
</style>
