import {
  ROUTE_ROLE,
  ROUTE_USER
} from '@/router/router-constants'
import User from '@/pages/base/user'
import Role from '@/pages/base/user/role'


export default [
  {
    name: ROUTE_USER,
    path: 'user',
    component: User,
    meta: { title: '用户管理' },
    children: [
      {
        name: ROUTE_ROLE,
        path: 'role',
        component: Role,
        meta: { title: '用户角色' }
      }
    ]
  }
]
