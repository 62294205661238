/**
 * @author jiangxingshang
 * @date 2022/12/13
 */
import kit from '@/utils/kit'

export default class User {
  constructor (initData) {
    this.id = null
    this.name = null
    this.username = null
    this.createDate = null
    this.disableDate = null
    this.token = null
    this.enabled = true
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
