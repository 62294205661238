import {
  ROUTE_PROPOSAL,
  ROUTE_ADS,
  ROUTE_AWARDS_ITEMS,
  ROUTE_AWARDS,
  ROUTE_CUSTOMER_PRIZES,
  ROUTE_CUSTOMER
} from '@/router/router-constants'
import Proposal from '@/pages/main/lottery/proposal'
import Ads from '@/pages/main/lottery/ads'
import AwardsItems from '@/pages/main/lottery/awards-items'
import Awards from '@/pages/main/lottery/awards'
import CustomerPrizes from '@/pages/main/lottery/prizes'
import Customer from '@/pages/main/customer'



export default [
  {
    name: ROUTE_PROPOSAL,
    path: 'proposal',
    component: Proposal,
    meta: { title: '方案设置' },
    children: [
      { name: ROUTE_ADS, path: 'ads/:id', meta: { title: '广告地址设置' }, component: Ads },
      { name: ROUTE_AWARDS_ITEMS,
        path: 'awards-items/:id',
        meta: { title: '奖项设置' },
        component: AwardsItems,
        children: [
          { name: ROUTE_AWARDS, path: 'awards/:itemsId', meta: { title: '奖品设置' }, component: Awards }
        ]
      }
    ]
  },
  {
    name: ROUTE_CUSTOMER_PRIZES,
    path: 'customer-prizes',
    component: CustomerPrizes,
    meta: { title: '客户抽奖记录' }
  },
  {
    name: ROUTE_CUSTOMER,
    path: 'customer',
    component: Customer,
    meta: { title: '客户' }
  }
]
