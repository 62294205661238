/**
 * @author zf
 * @date 2024/06/05
 */
import kit from '@/utils/kit'

export default class Ads {
  constructor (initData) {
    this.id = null
    this.url = null
    this.proposalId = null
    this.createTime = null
    this.seq = null
    this.createUserId = null
    this.seeDuration = 5
    if (initData) {
      kit.obj.getProperties(this, initData)
    }
  }
}
